
import { defineComponent, onMounted, reactive } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { ErrorMessage, Field, Form } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { useToast } from "vue-toastification";
import { ref } from "yup";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import Multiselect from "@vueform/multiselect";

export default defineComponent({
  name: "kt-widget-11",
  components: {
    Field,
    Loading,
    Multiselect,
  },
  props: {
    widgetClasses: String,
  },

  data() {
    return {
      // loading overlay setting
      isLoading: false,
      fullPage: true,
      isColor: "#007BFF",
      isBackgroundColor: "#A3A3A3",
      isOpacity: 0.5,
      // end loading overlay setting

      tableData: [],
      ListSchool: [],
      name: "",
      title: "",
      schoolId: "",
      paging: {
        page: 0,
        totalPages: 0,
        size: 10,
        totalElements: 0,
        perPageOptions: [10, 25, 50, 100],
      },

      aksesEdit: false,
      aksesDelete: false,

      role: "",
      filter: false,
    };
  },

  beforeMount() {
    store.dispatch(Actions.VERIFY_AUTH_FINANCE);
    this.role = JSON.parse(localStorage.getItem("user_account")!).role;
    this.getLocalStorage();
    this.cekRoleAkses();
    this.getData(this.paging.size, this.paging.page);
    this.getListSchool();
    setCurrentPageBreadcrumbs("Setting Finance", ["Data"]);
  },

  computed: {
    to() {
      let highBound = this.from + this.paging.size;
      if (this.paging.totalElements < highBound) {
        highBound = this.paging.totalElements;
      }
      return highBound;
    },
    from() {
      return this.paging.size * this.paging.page;
    },
  },

  methods: {
    cekRoleAkses() {
      switch (this.role) {
        case "SUPER_ADMIN":
          this.aksesEdit = true;
          this.aksesDelete = true;
          break;
        case "SALES_LEADER":
          this.aksesEdit = true;
          this.aksesDelete = false;
          break;
        case "SALES_AREA":
          this.aksesEdit = true;
          this.aksesDelete = false;
          break;
        case "CS_LEADER":
          this.aksesEdit = true;
          this.aksesDelete = false;
          break;
        case "CS_MEMBER":
          this.aksesEdit = true;
          this.aksesDelete = false;
          break;
        case "ADMIN_FINANCE":
          this.aksesEdit = true;
          this.aksesDelete = true;
          break;
      }
    },
    getLocalStorage() {
      if (
        localStorage.getItem("schoolId") &&
        localStorage.getItem("schoolId") != "null"
      ) {
        this.schoolId = localStorage.getItem("schoolId")!;
        console.log("getisi");
      } else {
        this.schoolId = "";
        console.log("getnull");
      }
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    getListSchool() {
      ApiService.getWithoutSlug("crmv2/main_school/school/school").then(
        ({ data }) => {
          this.ListSchool = data;
        }
      );
    },

    getData(size, page) {
      this.isLoading = true;

      let search = "";
      if (this.title) {
        search = "title=" + this.title + "&";
      } else {
        search;
      }

      let schoolId = "";
      if (this.schoolId) {
        schoolId = "schoolId=" + this.schoolId + "&";
      } else {
        schoolId;
      }

      let url = `crmv2/main_invoice/invoice/office/invoice/settings/all?itemType=Product&page=${page}&size=${size}&sort=createTime&dir=1`;
      if (this.title) url += `&title=${this.title}`;
      if (this.schoolId) url += `&schoolId=${this.schoolId}`;

      ApiService.getWithoutSlug(url)
        .then(({ data }) => {
          this.isLoading = false;

          this.tableData = data.content;
          this.paging.page = data.page;
          this.paging.size = data.size;
          this.paging.totalElements = data.totalElements;
          this.paging.totalPages = data.totalPages;

          console.log(this.schoolId);
          localStorage.setItem("schoolId", this.schoolId);
        })
        .catch(({ response }) => {
          this.isLoading = false;
        });
    },

    pilihSekolah(event) {
      // console.log(event.target.value);
      console.log(event);
      // this.schoolId = event;
      if (event) {
        this.schoolId = event;
        localStorage.setItem("schoolId", JSON.stringify(event));
        console.log("isi");
      } else {
        this.schoolId = "";
        localStorage.removeItem("schoolId");
        console.log("null");
      }
      this.getData(this.paging.size, this.paging.page);
    },

    handleDelete(index, item) {
      Swal.fire({
        title: "Apakah Anda Yakin ?",
        text: `Data yang telah dihapus tidak dapat dikembalikan !`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: "Ya, hapus !",
        cancelButtonText: "Batal",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteRow(item);
        }
      });
    },
    deleteRow(item) {
      this.isLoading = true;

      const toast = useToast();

      ApiService.delete(
        "crmv2/main_invoice/invoice/office/invoice/setting/" + item._id
      )
        .then((res) => {
          toast.success("Hapus Berhasil");

          this.getData(this.paging.size, this.paging.page);

          this.isLoading = false;
        })

        .catch((error) => {
          toast.error("Gagal Hapus");

          this.isLoading = false;
        });
    },
    filterClick() {
      this.filter = !this.filter;
    },
    range(min, max) {
      const arr: any[] = [];
      for (let i = min; i <= max; i++) {
        arr.push(i);
      }
      return arr;
    },

    changePage(item) {
      const page = item - 1;
      this.getData(this.paging.size, page);
    },
    nextPage() {
      const page = this.paging.page + 1;
      this.getData(this.paging.size, page);
    },
    prevPage() {
      const page = this.paging.page - 1;
      this.getData(this.paging.size, page);
    },
    changePageSize(e) {
      const page = this.paging.page;
      this.getData(this.paging.size, page);
    },
  },
});
